import React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/player/page/header.module.scss';

const PlayerHeader = ({ visual, firstName, lastName, number }) =>
    <div className={style.header}>
        {visual && visual.image.length !== 0 &&
            <ImageGatsby
                {...visual.image}
                alt={visual.alt}
            />
        }

        <h1 className="wrapper">
            <span className={style.header__firstName}>{firstName}</span>

            <span className={style.header__lastNameWrapper}>
                <span className={style.header__lastName}>{lastName}</span>
                <span className={style.header__lastNameEffect} data-text={lastName}/>
            </span>
        </h1>

        <span className={style.header__number}>{number}</span>
    </div>;

export default PlayerHeader;
