import React from 'react';
import { Script } from "gatsby";
import { Helmet } from 'react-helmet';

const WyscoutStatistics = ({ player }) => {

    let teamId = 6710;
    let competitionId = 808;
    let label = 'Allsvenskan';
    let playerName = `${player.firstName} ${player.lastName}`;

    if(player.currentTeam._id === 'team-253894') {
        teamId = 6803;
        competitionId = 809;
        label = 'OBOS Damallsvenskan';
        playerName = `${player.firstName} ${player.lastName}`;
    } else if( player.currentTeam._id === 'team-2') {
        teamId = 6710;
        competitionId = 808;
        label = 'Allsvenskan';
        playerName = `${player.firstName} ${player.lastName}`;
    }

    return (
        <>
            <Helmet>
            <script type="text/javascript">
            {`window.TWELVE_WIDGET_API_SECRET = '6b8d6bf1-423c-423c-89fd-874d7cc3b2aa'; console.log(window.TWELVE_WIDGET_API_SECRET );`}
            </script>
            </Helmet>
            <Script src="https://twelve.blob.core.windows.net/twelve-widgets/twelve-player-quality-widget.js" />
            <twelve-player-quality-widget competition-options={JSON.stringify([{ label, value: competitionId }])} player-id={player.wyscoutId} team-id={teamId} player-name={playerName}  />
        </>
    );
}

export default WyscoutStatistics;
