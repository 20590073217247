import React from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import FormattedMessage from 'components/utils/FormattedMessage';
import * as style from 'styles/components/player/page/achievements.module.scss';

const PlayerAchievements = ({ achievements }) => {
    const getFormattedPlayerAchievements = () => {
        // group sames achievements
        const uniqueAchievements = [
            ...new Map(achievements.map((item) => [item.achievement.id, item])).values(),
        ];

        // get years for each unique achievement
        const formattedAchievements = [];
        uniqueAchievements.forEach(({ achievement }) => {
            const currAchievementYears = achievements
                .filter((item) => item.achievement.id === achievement.id)
                .map((item) => item.year)
                .sort((a, b) => a - b);

            formattedAchievements.push({
                achievement: { ...achievement },
                years: currAchievementYears,
            });
        });

        // Return unique achievements sorted by the most recent year of obtaining
        return formattedAchievements.sort((a, b) => b.years.slice(-1) - a.years.slice(-1));
    };

    return (
        <>
            <h2 className="title-xs text-bold small-margin">
                <FormattedMessage id="player.awards" />
            </h2>

            <ul>
                {getFormattedPlayerAchievements().map((item, index) => (
                    <li key={index} className={style.achievement__item}>
                        <ImageGatsby
                            {...item.achievement.logo}
                            alt={item.achievement.title}
                            title={item.achievement.title}
                        />

                        <p className="text-sm-mobile text-md">{item.achievement.title}</p>

                        <div className={style.achievement__years}>
                            {item.years.map((year, yIndex) => {
                                const isLast = item.years.length - 1 === yIndex;

                                return (
                                    <span key={year} className="text-sm-mobile text-md">
                                        {year}
                                        {!isLast && ', '}
                                    </span>
                                );
                            })}
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export default PlayerAchievements;
