import React from 'react';

import PlayerPage from 'components/player/Page';

const PlayerItem = ({ pageContext }) =>
    <PlayerPage
        player={pageContext.player}
        playerStatistics={pageContext.playerStatistics}
        playerNews={pageContext.playerNews}
    />;

export default PlayerItem;
