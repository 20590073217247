// extracted by mini-css-extract-plugin
export var inactive = "profile-module--inactive--zXZBG";
export var profile__aboutContainer = "profile-module--profile__aboutContainer--d-T4j";
export var profile__aboutSection = "profile-module--profile__aboutSection--F33p1";
export var profile__bio = "profile-module--profile__bio--vOvyC";
export var profile__container = "profile-module--profile__container--EBvWB";
export var profile__getJersey = "profile-module--profile__getJersey--nI-WS";
export var profile__info = "profile-module--profile__info--Isq98";
export var profile__quote = "profile-module--profile__quote--ibL3c";
export var profile__socialContainer = "profile-module--profile__socialContainer--59548";
export var profile__statContainer = "profile-module--profile__statContainer--VBcR1";
export var profile__visual = "profile-module--profile__visual--GV-7W";
export var profile__visualContainer = "profile-module--profile__visualContainer--BIuyp";