import React, { useContext } from 'react';
import MetaTags from 'react-meta-tags';

import NewsBlockList from 'components/news/BlockList';
import PlayerHeader from 'components/player/pageParts/Header';
import PlayerProfile from 'components/player/pageParts/Profile';
import enMessages from 'i18n/en.json';
import seMessages from 'i18n/se.json';
import { LangContext } from 'context/LangContext';

const MESSAGES = {
    en: enMessages,
    se: seMessages,
};

const PlayerPage = ({ player, playerStatistics, playerNews }) => {
    const { lang } = useContext(LangContext);

    const url = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
            <MetaTags>
                <title>
                    {player.firstName} {player.lastName}
                </title>
                <meta
                    name="description"
                    content={
                        player.bio
                            ? player.bio.substring(0, 170)
                            : `${player.firstName} ${player.lastName}`
                    }
                />

                <meta property="og:title" content={`${player.firstName} ${player.lastName}`} />
                <meta
                    property="og:description"
                    content={
                        player.bio
                            ? player.bio.substring(0, 170)
                            : `${player.firstName} ${player.lastName}`
                    }
                />
                <meta property="og:url" content={url} />
                <meta property="og:site_name" content="Hammarby" />
                <meta property="og:type" content="player" />
                {player?.image[0]?.image && (
                    <meta property="og:image" content={player.image[0].image.asset.url} />
                )}

                <link rel="canonical" href={url} />
            </MetaTags>

            <PlayerHeader
                visual={player.headerImage}
                firstName={player.firstName}
                lastName={player.lastName}
                number={player.number}
            />

            <PlayerProfile player={player} playerStatistics={playerStatistics} />

            <div className="bg">
                {playerNews && playerNews.length !== 0 && (
                    <NewsBlockList
                        title={`${player.lastName} ${MESSAGES[lang.translationKey].player.news}`}
                        items={playerNews}
                    />
                )}
            </div>
        </>
    );
};

export default PlayerPage;
