import * as React from 'react';

import * as style from 'styles/components/player/page/field.module.scss';

const Field = ({ position }) => {
    const displayedPositions = [
        'goal', 'leftBack', 'centralDefender', 'rightBack', 'defCentralMidfielder', 'leftMidfielder',
        'OffCentralMidfielder', 'rightMidfielder', 'leftForward', 'centralForward', 'rightForward', 'coach',
    ];

    if (!displayedPositions.includes(position)) {
        return null;
    }

    // eslint disable consistent-return
    return (
        <div className={style.field__container}>
            <span className={`${style.field__pos} ${position}`} />

            <svg fill="none" height="179" viewBox="0 0 113 179" width="113" xmlns="http://www.w3.org/2000/svg">
                <g stroke="#739185">
                    <path d="m112.5.5h177.327v112h-177.327z" transform="matrix(0 1 -1 0 113 -112)"/>
                    <path d="m91.5742.5h28.2963v70.1482h-28.2963z" transform="matrix(0 1 -1 0 92.0742 -91.0742)"/>
                    <path d="m70.8164.5h9.74161v28.6393h-9.74161z" transform="matrix(0 1 -1 0 71.3164 -70.3164)"/>
                    <path d="m91.5742 149.772h28.2963v70.1482h-28.2963z" transform="matrix(0 1 -1 0 241.3462 58.1978)"/>
                    <path d="m70.9414 168.305h9.71037v28.5531h-9.71037z" transform="matrix(0 1 -1 0 239.2464 97.3636)"/>
                    <path d="m113 89.0428h-113.000015"/>
                    <path d="m56.1905 108.848c-10.6968 0-19.3682-8.67-19.3682-19.3655 0-10.6953 8.6714-19.3657
                19.3682-19.3657 10.6967 0 19.3681 8.6704 19.3681 19.3657 0 10.6955-8.6714 19.3655-19.3681 19.3655z"/>
                </g>
            </svg>
        </div>
    );
};

export default Field;
